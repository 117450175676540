/* ------------------specific--------------- */

body {
  position: relative;
  min-height: 100vh;
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
  /* margin-bottom: 1rem; */
  /* 
  mar
  padding-bottom: 1rem; */
  /*  overflow-y: hidden; /* Hide vertical scrollbar 
  overflow-x: hidden; /* Hide horizontal scrollbar  */
}

/* ---------------footer style -------------------- */

.footer {
  /*  footer stick to bottom */
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
}

.footerPolicy {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  display: inline;
}

.footerPolicy p {
  display: inline-block;
  margin-left: 15px;
}

@media (max-width: 411px) {
  .footerPolicy {
    font-size: 0.9rem;
    margin-bottom: 55px;
  }
}

/*  ---------------- category ----------------- */
.category {
  display: grid;
  place-items: space-between;
  grid-template-columns: repeat(6, 1fr);
}

.category-img {
  height: 5rem;
  width: 5rem;
  display: block;
  margin: auto;
  margin-bottom: 0px;
}

.category-img img {
  border-radius: 1rem;
  cursor: pointer;
}

.category-btn {
  display: flex;
  justify-content: center;
}

.category-btn button {
  font-family: var(--bs-font-sans-serif);
  color: #2aa198;
  font-size: 16px;
  font-weight: 600;
  word-spacing: 10px;
  padding: 0;
  word-spacing: 1rem !important;
}

.subCategory-btn button {
  display: flex;
  justify-content: center;
  width: 5.8rem;
  height: 2.5rem;
  margin: 0.2rem;
  background-color: rgb(255, 255, 255);
  color: #268BD2;
  border: 1px solid #268BD2;
}

@media screen and (max-width: 450px) {
  .subCategory-btn button {
    width: 5rem;
    font-size: 0.85rem;
  }
}




/* ---------------- category responsive -------------------------*/

@media (min-width: 100px) {
  .category {
    display: grid;
    place-items: space-between;
    grid-template-columns: repeat(6, 2fr);
  }

  .category-img {
    height: 2rem;
    width: 2rem;
    display: block;
    margin: auto;
    margin-bottom: 0px;
  }

  .category-btn button {
    font-size: 10px;
  }
}

@media (min-width: 300px) {
  .category {
    display: grid;
    place-items: space-between;
    grid-template-columns: repeat(4, 1fr);
  }

  .category-img {
    height: 5rem;
    width: 5rem;
    /* background-color: rgb(0, 0, 0, 0.2); */
  }

  .category-img img {
    /*  background-color: #839496;
    width: 6rem;*/
    width: 100%;
    height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .category-btn button {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .category {
    display: grid;
    place-items: space-between;
    grid-template-columns: repeat(4, 1fr);
  }

  .category-img {
    height: 8rem;
    width: 8rem;
  }

  .category-btn button {
    font-size: 17px;
  }
}

@media (min-width: 992px) {
  .category {
    display: grid;
    place-items: space-between;
    grid-template-columns: repeat(6, 1fr);
  }

  .category-img {
    height: 4rem;
    width: 4rem;
  }

  .category-btn button {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .category {
    display: grid;
    place-items: space-between;
    grid-template-columns: repeat(6, 1fr);
  }

  .category-img {
    height: 5rem;
    width: 5rem;
  }

  .category-btn button {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  .category {
    display: grid;
    place-items: space-between;
    grid-template-columns: repeat(6, 1fr);
  }

  .category-img {
    height: 5rem;
    width: 5rem;
  }

  .category-btn button {
    font-size: 16px;
  }
}

/*  ---------------- sub-category ----------------- */
.sub-category {
  display: grid;
  place-items: space-between;
  grid-template-columns: repeat(8, 1fr);
}

.sub-category-img {
  height: 6rem;
  width: 6rem;
  display: block;
  margin: auto;
  margin-bottom: 0px;
}

/*  ---------------- sub-category  responsive----------------- */
@media screen and (max-width: 650px) {
  .sub-category {
    display: grid;
    place-items: space-between;
    grid-template-columns: repeat(4, 1fr);
  }

  .sub-category-img {
    height: 4.8rem;
    width: 4.8rem;
    display: block;
    margin: auto;
    margin-bottom: 0px;
  }
}

@media (min-width: 650px) and (max-width: 769px) {
  .sub-category {
    display: grid;
    place-items: space-between;
    grid-template-columns: repeat(6, 1fr);
  }

  .sub-category-img {
    height: 6rem;
    width: 6rem;
    display: block;
    margin: auto;
    margin-bottom: 0px;
  }
}

/* ------------------------------------cart-------------------------- */

#cart {
  position: relative;
}

.cart-item {
  position: absolute;
  top: 1px;
  left: 13px;
  background-color: rgba(251, 255, 4, 0.9);
  height: 14px;
  width: 14px;
  border-radius: 50%;
  text-align: center;
}

.cart-item h6 {
  color: black;
  font-size: small;
  font-weight: 600;
}


@media screen and (max-width: 450px) {
  .cart-item {
    position: absolute;
    top: -3px;
    left: 17px;
    background-color: rgba(251, 255, 4, 0.9);
    height: 14px;
    width: 14px;
    border-radius: 50%;
    text-align: center;
  }

  .cart-item h6 {
    color: black;
    font-size: small;
    font-weight: 900;
  }
}


/* ------------------------cart responsive------------ */

@media (max-width: 320px) {
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
  }

  .row>* {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 50%;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: var(--bs-gutter-y);
  }
}

.zoom {
  transition: transform 0.2s;
  /* ------------Animation------------- */
}

.zoom:hover {
  transform: scale(1.05);
}

/* ---------------carousel ---------------------*/
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #000;
}

.carousel img {
  height: 250px;
  padding: 30px;
  margin: 40px;
  border-radius: 16%;
  margin-left: auto;
  margin-right: auto;
}

.carousel a {
  margin: 0 auto;
}

.nav {
  margin-left: auto;
}

/* --------------------------sign in button alignment------------------ */
/*------------ login with phone number page ----------------- */

.move-right {
  text-align: right;
}

/* .move-right>button {
  margin: 0 10px;
}
 */
.phoneNumber {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  background: #ffffff;
  border-radius: 5px;
}

.phoneNumber h6 {

  color: black;
  font-size: 1.5rem;
  margin-bottom: 0.8rem;

}

.form1 {
  padding: 10px;
  box-sizing: border-box;
}

.form2 {
  padding: 10px;
  box-sizing: border-box;
}

@media (min-width: 500px) and (max-width: 2562px) {
  /*  .loginWithPhone {
    position: relative;
    height: calc(100vh - 10rem);
  } */

  .phoneNumber {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30em;
    background: rgba(232, 232, 232, 0.5);
    border-style: outset;
    border-radius: 10px;
  }

  .phoneNumber h2 {
    font-size: 1.8em;
    text-align: center;
    margin-top: 1rem;
  }
}

/* ---------------------header shadow------------- */

.headerShadow {
  box-shadow: 0px 1px 5px #2aa198;
}

.footerNavbar {
  box-shadow: 0px 0px 3px #2aa198;
  background-color: #ffffff;
  padding: 0.5rem;

}

.footerNav {
  padding: 0;
}

/* ---------------footer------------------- */

/* .footer-bottom {
  position: relative;
  bottom: 0;
}
 */

.link-1 {
  color: #839496;
  text-underline-offset: 2px;
}

/* -------------------offer & price -------------- */

.productPrice {
  display: inline;
  font-size: 25px;
}

.productMrp {
  color: #555555;
  display: inline;
  margin-left: 20px;
}

.productOffer {
  display: inline;
  color: green;
  margin-left: 10px;
  font-size: large;
}

/* -------------cart-icon----------- */

.cartIconMobile {
  display: none;
  float: right;
  color: #2aa198;
}

.cartIconLaptop {
  float: right;
  color: #2aa198;
}

/* ------------ */

.con {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------------step color--------- */

.step-color {
  color: #2aa198;
}

@media (max-width: 400px) {
  .textSizeM {
    font-size: 12px;
  }
}

/* --------------tab link color--------- */
.textTabColor title {
  color: #000;
}

.nav-item {
  color: #000;
}

/* =-------------Table Head --------------- */

.tableHead {
  color: black;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
}

.tableData {
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
}

/* --------------------dashboard---------------- */

.DashboardHeading {
  text-align: center;
  color: black;
  font-weight: 600;
  font-size: 1.8rem;
}

/* ------------------------ Responsive productlist screen ----------  */
@media (max-width: 800px) {
  .listSize {
    font-size: 0.7rem;
  }
}

/* -------------------  Responsive Image   ------------------ */

.slider img {
  margin: 0;
  padding: 0;
  border-radius: 0;
}

@media (min-width: 1024px) {
  .slider img {
    height: 350px;
  }
}

@media (max-width: 425px) {
  .slider img {
    height: 350px;
  }

  /* -------------------------------home screen cart space removed */
  /* .row > * {
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 5px;
  }

  .my-3 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  } */
}

/* -----------------------  cart image to set fit --------------- */

.fitImage {
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.fitImageList {
  height: 80px;
  width: 100%;
  object-fit: cover;
}

.fitImageCart {
  height: 90px;
  width: 90px;
  object-fit: fil;
}

/* -----------------------  cart card set in mobile --------------- */

@media screen and (max-width: 480px) {
  .mobileHiddenList {
    display: none;
  }
}

@media screen and (min-width: 481px) {
  .mobileHidden {
    display: none;
  }
}

/* -----------------------  add to cart button on mobile --------------- */

@media screen and (max-width: 480px) {
  #add-to-cart {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 3.5rem;
    z-index: 3;
    left: 0px;
  }

  #proceed {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 3.5rem;
    z-index: 3;
    left: 0px;
  }

  #place-order {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 3.5rem;
    z-index: 3;
    left: 0px;
  }

  #payment {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 3.5rem;
    z-index: 3;
    left: 0px;
  }
}

/* -------------------removing a padding and margin from card ---------- */

.row>* {
  padding-right: 5px;
  padding-left: 5px;
  margin-top: 5px;
}

.my-3 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

/* -------------------card carousel  ---------- */

.react-multi-carousel-list ul:first-child li {
  padding: 5px;
}

/* --------------------heading style for carousel------------ */
.carouselHeading {
  background-color: rgb(232, 232, 232, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', 'sans-serif';
  color: #2aa198;
}

/* ----------------------------    card line ---------------- */

.bottomLine {
  display: flex;
  height: 0.06rem;
  width: 12.8rem;
  background-color: #bbb;
  float: left;
  margin: 0.8rem 0rem 0rem 0rem;
  padding: 0px;
}

/* --------------------------------------- card  extra design ------------------------------- */

.card-name {
  font-size: 1.2rem;
  color: #2aa198;
  font-weight: 400;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-name-size {
  font-size: 0.9rem;
  color: #5e5e5e;
  font-weight: 600;
  margin-bottom: 0;
  text-align: right;
}

.card-text-inline {
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem; */
  display: inline;
  color: #000;
  /* 
  font-family: 'Work Sans,sans-serif'; */
}

.card-text-price {
  font-size: 1.5rem;
}

.card-text-mrp {
  color: #551;
  font-size: 1rem;
  margin-left: 0.8rem;
  text-decoration: line-through;
  text-decoration-color: #f08080;
  font-family: 'Work Sans,sans-serif';
}

.card-text-off {
  color: white;
  background-color: #f14806;
  font-size: 1rem;
  position: absolute;
  top: 8px;
  right: 0px;
  padding: 0px 4px;
  border-radius: 4px 0px 0px 4px;
}

.cartButton {
  display: none;
}

.bundle-card-name {
  font-size: 1rem;
  color: #2aa198;
  font-weight: 400;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bundle-card-img {
  max-width: 6rem;
  height: 5rem;
}

@media (max-width: 480px) {
  .carouselHeading {
    background-color: #4cb5ac;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: 0.1rem;
    padding: 0.15rem;
    margin: 0;
    font-family: 'Roboto', 'sans-serif';
    color: #ffffff;
    font-size: 1rem;
  }

  .card-size {
    max-width: 8rem;
    height: 12.5rem;
  }

  .bundle-card-size {
    max-width: 8rem;
    height: 7rem;
  }

  .card-img {
    max-width: 7rem;
    height: 6rem;
  }

  .bundle-card-img {
    max-width: 4rem;
    height: 3rem;
  }

  .card-text-off {
    font-size: 0.7rem;
  }

  .card-name {
    margin-top: -0.5rem;
    font-weight: 600;
    letter-spacing: 0.04rem;
    font-size: 1rem;
  }

  .bundle-card-name {
    font-size: 0.8rem;
    color: #2aa198;
    font-weight: 400;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bundle-card-name-size {
    font-size: 0.6rem;
    color: #717171;
    font-weight: 400;
    margin-bottom: 0;
    text-align: right;
  }

  .card-size-body {
    padding: 0.05rem;
  }

  .card-text-mrp {
    font-size: 0.9rem;
    margin-left: 0.3rem;
  }

  .bundle-card-text-mrp {
    color: #551;
    font-size: 0.6rem;
    margin-left: 0.8rem;
    text-decoration: line-through;
    text-decoration-color: #f08080;
    font-family: 'Work Sans,sans-serif';
  }

  .card-text-price {
    font-weight: 500;
    color: #000;
    font-size: 1.2rem;
  }

  .bundle-card-text-price {
    font-weight: 500;
    color: #000;
    font-size: 0.7rem;
  }


  p {
    margin: 0;
  }

  .cartIconMobile {
    display: block;
    margin-top: 0.2rem;
    font-size: 0.8rem;
  }

  .cartIconLaptop {
    display: none;
  }


}

@media (min-width: 1024px) {
  .card-text-mrp {
    font-size: 1rem;
    margin-left: 0.9rem;
  }

  .card-text-price {
    font-size: 1.5rem;
  }
}

.countAllBtn {
  display: inline;

}


.countInput {
  width: 2rem;
  height: 1.8rem;
  text-align: center;
  border-radius: 0;
  border: 0;

}

.countButton {
  display: block;
  font-size: 0.7rem;
  margin-top: 0.2rem;
  padding: 0.3rem;
}

.btn-outline-success {
  color: #268BD2;
  border-color: #268BD2;

}

.btn-outline-success:hover {
  color: #fff;
  background-color: #268BD2;
  border-color: #268BD2;
}

.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 210, 0.5);


}

/* ----------------------------------toaster--------------- */

.toaster-start {
  position: relative;
  background-color: white;
  border: 0;
}

.toaster-head {
  background: rgb(86, 86, 86);
  position: absolute;
  bottom: 1px;
  left: 80px;
  width: 6rem;
  height: 3rem;
  padding: 0.3rem;
  justify-content: center;
  text-align: center;
}

.toaster-text {
  color: white;
  font-size: 0.75rem;
  border-radius: 0rem;
  font-family: 'Work Sans,sans-serif';
}

@media (max-width: 480px) {
  .toaster-start {
    top: -5px;
    left: -75px;
  }

  .toaster-head {
    height: 3rem;
    width: 5rem;
    padding: 0.3rem;
    justify-content: center;
    text-align: center;
  }

  .toaster-text {
    color: white;
    font-size: 1rem;
    font-weight: 900;
    border-radius: 0rem;
    font-family: 'Work Sans,sans-serif';
  }
}

/* --------------------carousel image---------------------- */

.carouselImage {
  width: 100%;
  height: 350px;
  border-radius: 10px;
}

@media (max-width: 480px) {
  .carouselImage {
    width: 100%;
    height: 150px;
    border-radius: 5px;
  }
}

/* ---------------------------sreach buttom---------------- */

.white_button {
  height: 2.3rem;
  background-color: #fff;
  border-radius: 0.2rem;
  border: 1px solid rgb(54, 54, 54);
  color: #000;
}

.white_button:hover {
  background-color: rgb(220, 220, 220);
  border: 1px solid #ccc;
  color: #000;
}

.card-body {
  padding: 0.4rem 0.2rem 0.2rem 0.2rem;
}


/* ------------------------home bottom button ------------------ */

/* .size {
  height: 0.1rem;
  width: 0.1rem;
} */

.allicons {
  padding: 0 0.5rem;
  /*   color: rgb(150, 150, 150); */
  color: rgb(0, 0, 0, 0.75);
}

.allicons:hover {
  color: #2aa198;
}

.iconcenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: 0.9rem;
}


.alertMessage {
  background-color: #E64B4B;
  color: #000;
  font-size: 1.2rem;
  position: fixed;
  z-index: 4;
}


.alertMessage {
  animation: cssAnimation 10s forwards;
}

@keyframes cssAnimation {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.centerSearch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form-check .form-check-input {
  background-color: rgb(187, 187, 187, 0.5);
  border: 2px solid #74c3fb;
}

.form-check-input:checked {
  background-color: #2aa198;
  color: #2aa198;
}

.login-Screen {
  /* background: rgb(76, 142, 233); */

  /* background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%); */
  /*   background: rgb(38, 139, 210);
  background: linear-gradient(90deg, rgba(38, 139, 210, 0.8015581232492998) 44%, rgba(42, 161, 152, 0.700717787114846) 45%); */
  height: 85vh;
  width: 95vw;
  padding: 0;
}

.login-Screen p {
  position: absolute;
  top: 85%;
  left: 50%;
  width: 90vw;
  transform: translate(-50%, -50%);
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  font-size: 1.3rem;
  min-height: 40px;
  border: 2px solid #2aa198;
  border-radius: 5px;
}

.PhoneInputCountrySelect option {
  height: 40px;
}


/* ------------------------retailCenter Home Screen ------------------ */

.close {
  width: 1.5rem;
  font-size: 1.3rem;
  border: none;
  background-color: white;
  margin: 0;

}

.close :nth-child(1) {
  font-size: 2rem;
}

.disabledLogin {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}