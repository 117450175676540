/* body a {
	text-decoration: none;
}

.nav1 {
	background-color: rgb(42, 161, 152);
}

h2 {
	text-align: center;
	font-family: merriweather, sans-serif;
}

.fasCheck {
	color: rgb(42, 161, 152);
	float: right;
}

#support {
	margin-top: 3rem;
}

#support span {
	font-family: merriweather, sans-serif;
}

section.info {
	margin-right: 3rem;
	margin-bottom: 5rem;
}

section.d-flex span {
	margin-left: 8rem;
}

section.mb-4 .card-wrapper {
	text-align: center;
	margin-bottom: rem;
}

body {
	font-family: Lato, sans-serif;
}
 */
* {
	margin: 0px;
	padding: 0px;
}

*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: '';
}

.clearfix:after {
	clear: both;
}

html {
	scroll-behavior: smooth;
}

/* body {
	background: #ffffff;
	color: #333;
	font-weight: normal;
	font-size: 1rem; 
	font-family: 'Roboto', Arial, sans-serif;
}*/

input:focus,
textarea:focus,
keygen:focus,
select:focus {
	outline: none;
}

::-moz-placeholder {
	color: #666;
	font-weight: 300;
	opacity: 1;
}

::-webkit-input-placeholder {
	color: #666;
	font-weight: 300;
}

/* Contact Form Styling */

.textcenter {
	text-align: center;
}

.section1 {
	text-align: center;
	display: table;
	width: 100%;
}

.section1 .shtext {
	display: block;
	margin-top: 20px;
	font-size: 40px;
}

.section1 .seperator {
	border-bottom: 1px solid #a2a2a2;
	width: 35px;
	display: inline-block;
}

/*
.section1 h1 {
  font-size: 20px;
  color: #a44dd8;
  font-weight: normal;
}
*/

.section2 {
	width: 100%;
}

.section2 .col2 {
	width: 48.71%;
}

.section2 .col2.first {
	float: left;
}

.section2 .col2.last {
	float: right;
}

.section2 .col2.column2 {
	padding: 0 30px;
}

.section2 span.collig {
	color: #a2a2a2;
	margin-right: 10px;
	display: inline-block;
}

.section2 .sec2addr {
	display: block;
	line-height: 26px;
}

.section2 .sec2addr p:first-child {
	margin-bottom: 10px;
}

.section2 .sec2contactform input[type='text'],
.section2 .sec2contactform input[type='email'],
.section2 .sec2contactform textarea {
	padding: 18px;
	border: 0;
	background: #ededed;
	margin: 7px 0;
}

.section2 .sec2contactform textarea {
	width: 100%;
	display: block;
	color: #666;
	resize: none;
}

.section2 .sec2contactform input[type='submit'] {
	padding: 15px 40px;
	color: #fff;
	border: 0;
	background: #a44dd8;
	font-size: 16px;
	text-transform: uppercase;
	margin: 7px 0;
	cursor: pointer;
}

.section2 .sec2contactform h3 {
	font-weight: normal;
	margin: 20px 0;
	margin-top: 30px;
	border-bottom: 1px solid #ddd;
	padding-bottom: 19px;
	color: #a44dd8;
}

/* @media querries */

@media only screen and (max-width: 1266px) {
	.section2 {
		width: 100%;
	}
}

@media only screen and (max-width: 960px) {

	/* .container {
    padding: 0 30px 70px;
  } */
	.section2 .col2 {
		width: 100%;
		display: block;
	}



	.section2 .col2.column2 {
		padding: 0;
	}

	body .sec2map {
		height: 250px !important;
	}
}

@media only screen and (max-width: 768px) {
	.section2 .sec2addr {
		font-size: 14px;
	}

	.section2 .sec2contactform h3 {
		font-size: 16px;
	}

	.section2 .sec2contactform input[type='text'],
	.section2 .sec2contactform input[type='email'],
	.section2 .sec2contactform textarea {
		padding: 10px;
		margin: 3px 0;
	}

	.section2 .sec2contactform input[type='submit'] {
		padding: 10px 30px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 420px) {
	.section1 h1 {
		font-size: 28px;
	}
}

.section2 .col2.last {
	margin-top: 3rem;
}

section#profile h1 {
	text-align: center;
	font-family: merriweather, sans-serif;
	margin-top: 5rem;
	margin-bottom: 1rem;
}

section#about h1 {
	text-align: center;
	font-family: merriweather, sans-serif;
	margin-top: 5rem;
	margin-bottom: 1rem;
}

section#profile .card:hover {
	box-shadow: 4px 4px rgb(42, 161, 152, 0.5);
	transform: scale(1.05);
}

section#service .card:hover {
	box-shadow: 4px 4px rgb(42, 161, 152, 0.5);
	transform: scale(1.05);
}

section#support .card:hover {
	box-shadow: 4px 4px rgb(42, 161, 152, 0.5);
	transform: scale(1.05);
}

/*  ----------------navbar------------- 
.navContent {
	padding: 1%;
	padding-left: 10%;
}
 */
@media only screen and (min-width: 767px) {
	.navContent {
		margin-bottom: 3rem;
	}
}

/* ----------------make section center---------- */

.spaceEvenly {
	justify-content: space-evenly;
}

/* .buttonStyle {
	border: 2px solid rgb(42, 161, 152);
}

.buttonStyle:hover {
	background-color: rgb(42, 161, 152);
}
 */
.buttonStyle a {
	font-size: 1.2rem;
	text-decoration: none;
	color: rgb(255, 255, 255);
	font-weight: 500;
}

.shtext1 {
	display: block;
	margin-top: 20px;
	font-size: 40px;
	font-family: merriweather, sans-serif;
}

.seperator1 {
	border-bottom: 1px solid #a2a2a2;
	width: 35px;
	display: inline-block;
	margin: 30px;
}

/* -----------------card img------------ */

.ImgCard {
	height: 110px;
	border-radius: 50%;
	width: 40%;
	margin-top: 40px;
}

/* .navbarRight {
	color: #fff;
} */

/* .headLink {
  color: rgb(255, 0, 0) !important;
}
 */

/* -------------map--------- */

.mapouter {
	position: relative;
	text-align: right;
	width: 100%;
	height: 460px;
}

.gmap_canvas {
	overflow: hidden;
	background: none !important;
	width: 100%;
	height: 460px;
}

.gmap_iframe {
	height: 460px !important;
}

.cardHead ul {
	list-style-type: none;
}

.cardHead ul li {
	color: black;
	font-size: 1.2rem;
	font-family: 'Times New Roman', Times, serif;
}

.fasCheck {
	margin-right: 2rem;
	float: right;
	color: #2aa198;
	font-size: 1.2rem;
}

.color {
	color: #000;
}

.colorHeading {
	color: #000;
	text-decoration: underline;
}

.footerStyle {
	margin: auto;
	width: 50%;
}

.footerStyle p {
	text-align: center;
}

.retailHeading {
	display: inline-block;
	margin-top: 20px;
	font-size: 30px;
	font-family: merriweather, sans-serif;
	color: '#000'
}

.retailpara {
	display: block;
	margin-top: 20px;
	font-size: 40px;
	font-family: merriweather, sans-serif;
	color: '#000'
}





@media (max-width: 800px) {
	.retailHeading {
		display: inline-block;
		margin-top: 20px;
		font-size: 21px;
		font-family: merriweather, sans-serif;
		color: '#000'
	}

	.retailpara {
		display: block;
		margin-top: 20px;
		font-size: 25px;
		font-family: merriweather, sans-serif;
		color: '#000'
	}
}

@media (max-width: 450px) {
	.retailHeading {
		display: inline-block;
		margin-top: 20px;
		font-size: 15px;
		font-family: merriweather, sans-serif;
		color: '#000'
	}

	.retailpara {
		display: block;
		margin-top: 20px;
		font-size: 20px;
		font-family: merriweather, sans-serif;
		color: '#000'
	}

	.retailBtn {
		display: hidden;
		margin: 0;
		font-size: 1rem;
	}
}